/* You can add global styles to this file, and also import other style files */
@import "/node_modules/flag-icons/css/flag-icons.min.css";
@import "/node_modules/bootstrap-icons/font/bootstrap-icons.css";

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
  color: #000;
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a {
  color: #007bff;
  font-weight: 700;
  background-color: transparent;
}

.fs14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.word-break {
  word-break: break-word;
}

.word-break-all {
  word-break: break-all;
}

.no-pointer {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.btn,
.btn-normal {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;

  color: #fff !important;
  // background-color: transparent;
  border-color: transparent;
  border: 0.063rem solid transparent;
  border-radius: 0.25rem;

  white-space: nowrap;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;

  padding: 0.375rem 0.75rem;
  margin-top: 0.063rem !important;

  height: 2.5rem;
  width: auto;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary,
.active {
  color: #fff !important;
  background: linear-gradient(to right, #f9a856, #f27360) !important;
  border-color: #da6756 !important;
}
.btn-primary:disabled {
  opacity: 0.65;
}

.btn:hover,
.btn:active,
.btn:focus,
.btn:visited,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited {
  color: #fff;
  background: linear-gradient(to right, #e09952, #da6756) !important;
  border-color: #da6756 !important;
}

.btn-cancel {
  padding: 10px;
  color: #f27360 !important;
  background: white;
  border: solid 1px #da6756;
  border-radius: 5px;
  transition: all 0.4s ease;
}

.btn-cancel:hover,
.btn-cancelś:active {
  color: white !important;
  background: #f27360 !important;
  transition: all 0.4s ease;
}

.flex-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.card {
  background: #fff;
  padding: 0.625rem;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0 rgb(0 0 0 / 24%);
}
.card-title > span {
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.25rem;
}
.card-title > span::after {
  content: "";
  margin-top: 0.313rem;
  display: block;
  width: 100%;
  border-bottom: 2px solid #f27360;
}

.bg-grey {
  background-color: #eee !important;
}

.input-group-append {
  margin-left: -3px !important;
  display: -webkit-inline-box;
}

// Margin
.mb-5 {
  margin-bottom: 5rem !important;
}

// font size css
.f-16 {
  font-size: 1rem !important;
  line-height: 1.5;
  font-weight: 400;
}

.require {
  color: red !important;
}

.tooltip {
  z-index: 1070;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  position: relative;
  display: inline-block;
}

.field-validation-info {
  color: grey;
  float: right;
  font-size: 10px;
  font-weight: normal;
}

.label-font-weight-normal {
  font-weight: normal;
}

.accordion-panel-head {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.accordion-box {
  margin-bottom: 1.2rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

p.sign-up-message {
  color: #777777;
}

.font-bold {
  font-weight: bold;
}

.grey-color {
  color: #888 !important;
}

.font-14 {
  font-size: 14px !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-image: none;
}

.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
  height: 40px;
}

span#character-counter {
  color: grey;
  float: right;
  font-size: 11px;
}

.z-index-999 {
  z-index: 999;
}

.invalid {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.card-view-button {
  background: linear-gradient(to right, #f9a856, #f27360);
  color: #fff !important;
  border-radius: 5px;
  float: right;
  padding: 5px 14px;
  font-weight: 700;
  font-size: 13px;
  white-space: nowrap;

  &:hover {
    color: #fff;
    background: linear-gradient(to right, #e09952, #da6756) !important;
    border-color: #da6756 !important;
  }
}

.card-view-header,
.card-view-subtitle {
  font-size: 18px !important;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #444 !important;
}

.card-view-header > h4 {
  font-size: 18px !important;
}

.card-view-body {
  font-size: 16px;
}

.text-gray {
  color: #96a2b2 !important;
}

.text-align-justify {
  text-align: justify !important;
}

.vertical-ellipsis-position {
  .dropdown-menu {
    --bs-dropdown-min-width: 8.5rem !important;
    transform: translate(-36px, -5px) !important;
  }
}

.badge-primary,
.badge-secondary {
  font: 14px/1 FontAwesome;
}

.mt30 {
  margin-top: 30px;
}

h4 {
  font-size: 18px;
  font-weight: 700;
}

.head {
  // margin: -15px 0 30px !important;
  display: inline-block;

  ::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 2px solid #f27360;
  }
}

.form-select.input,
.form-control.input {
  height: 40px !important;
  border-radius: 3px !important;
  transition: none;
  /* border: solid 1px #dee2e6; */
  box-shadow: none;
}
.form-control.inputarea {
  border-radius: 3px !important;
  transition: none;
  /* border: solid 1px #dee2e6; */
  box-shadow: none;
}

@media print {
  @page {
    size: A4;
    margin: 0 !important;
    padding: 0 !important;
  }
  body {
    margin: 0;
  }
}